import React from 'react'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
    const navigate = useNavigate()
    return (
        <div className='notFound'>

            <div className="content">
                <h1>404</h1>
                <p>Sorry,the page you are looking for could not be found</p>
                <div className="buttonContainer" >
                    <div className="background"></div>
                    <button onClick={() => navigate("/")} >Back to home</button>
                </div>
            </div>
        </div>
    )
}

export default NotFound