import React, { useState } from 'react';
import Modal from 'react-modal';
import './custom.scss'

Modal.setAppElement('#root'); // Bind modal to root element

const ConfirmationModal = ({ onConfirm }) => {
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleConfirmAction = () => {
        handleCloseModal();
        // Call function to perform action here
        onConfirm();
    };

    return (
        <React.Fragment>
            <button className='delete-btn' onClick={handleOpenModal}>Delete</button>
            <Modal isOpen={showModal} onRequestClose={handleCloseModal}>
                <h2>Are you sure you want to delete?</h2>
                <div className="modal-buttons">
                    <button className='cancel' onClick={handleCloseModal}>Cancel</button>
                    <button onClick={handleConfirmAction}>OK</button>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default ConfirmationModal;
