import axios from "axios";
import { Auth } from '@aws-amplify/auth';

const getConfigWithToken = async () => {
    try {
        const session = await Auth.currentSession();
        const token = session.getAccessToken().getJwtToken();
        return {
            headers: {
                "token": token
            }
        };
    } catch (error) {
        console.log(error);
        return {
            headers: {}
        }
    }
}

const fetchUpsell = async (cartId) => {

    const config = await getConfigWithToken();

    const body = {
        id: cartId
    }
    return await axios.post(process.env.REACT_APP_API_URL + "/upsell/list/", body, config);
}


const fetchRules = async () => {
    const config = await getConfigWithToken();
    return await axios.get(process.env.REACT_APP_API_URL + "/rule/list/", config)
}

const createRule = async (data) => {
    const config = await getConfigWithToken();
    return await axios.post(process.env.REACT_APP_API_URL + "/rule/create", data, config)
}

const updateRule = async (data) => {
    const config = await getConfigWithToken();
    return await axios.post(process.env.REACT_APP_API_URL + "/rule/update", data, config)
}

const updateRuleOrder = async (data) => {
    const config = await getConfigWithToken();
    return await axios.post(process.env.REACT_APP_API_URL + "/rule/update/order", data, config)
}

const updateRuleStatus = async (data, ruleId) => {
    const config = await getConfigWithToken();
    return await axios.post(process.env.REACT_APP_API_URL + '/rule/update/status?rule_id=' + ruleId, data, config)
}

const deleteRule = async (ruleId) => {
    const config = await getConfigWithToken();
    return await axios.post(process.env.REACT_APP_API_URL + '/rule/delete?rule_id=' + ruleId, null, config)
}

const deleteRuleCondition = async (conditionId) => {
    const config = await getConfigWithToken();
    return await axios.post(process.env.REACT_APP_API_URL + '/rule/condition/delete?condition_id=' + conditionId, null, config)
}

const fetchRuleDetails = async (ruleId) => {
    const config = await getConfigWithToken();
    return await axios.get(process.env.REACT_APP_API_URL + '/rule/details?rule_id=' + ruleId, config)
}

const fetchRuleEvents = async (token) => {
    const config = await getConfigWithToken();
    return await axios.get(process.env.REACT_APP_API_URL + "/rule/event/list/", config)
}
const fetchTimeZones = async (token) => {
    const config = await getConfigWithToken();
    return await axios.get(process.env.REACT_APP_API_URL + "/timezones", config)
}

export const service = {
    fetchRules,
    fetchRuleEvents,
    updateRuleOrder,
    updateRuleStatus,
    deleteRule,
    deleteRuleCondition,
    fetchRuleDetails,
    updateRule,
    createRule,
    fetchTimeZones,
    fetchUpsell
}