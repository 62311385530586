import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './custom.scss';
import { useNavigate } from 'react-router-dom';
import { service } from './service';
import { BarLoader } from "react-spinners";

const UpsellList = () => {
  const navigate = useNavigate();
  const [cartId, setCartId] = useState();
  const [fetchUpsell, setFetchUpsell] = useState(false)
  const [upsellList, setUpsellList] = useState([])
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUpsell && cartId && fetchUpsells()
  }, [fetchUpsell])

  const fetchUpsells = async () => {
    setLoading(true)
    try {
      const upsellResponse = await service.fetchUpsell(cartId);

      console.log(upsellResponse);

      if (upsellResponse.status == 200) {
        setLoading(false)
        setFetchUpsell(false)
        setUpsellList(upsellResponse.data.map((upsell, index) => {
          let { id, event_name, event_description, event_image, event_share_url, event_subtitle, event_summary, type, sessions } = upsell
          let limit = 150;
          if (event_summary.length > limit) {
            event_summary = event_summary.substring(0, limit) + "...";
          }
          return <div className="gridItem">
            <a href={event_share_url} target='_blank'><div className='inner-wrapper'>
              <div className='image-wrapper'><img src={event_image} /></div>
              <div className='title'>{event_name}</div>
              <div className='sub-title'>{event_subtitle}</div>
              <div className='summary'>{event_description}</div>
              <div className='summary'>{event_summary}</div>
            </div></a>
          </div>
        }))
      } else {
        setLoading(false)
        setFetchUpsell(false)
        showExceptionMessage()
      }
    } catch (error) {
      setLoading(false)
      setFetchUpsell(false)
      showExceptionMessage()
    }
  }


  const showExceptionMessage = () => {
    toast.error('Invalid cart id!')
  }

  return (
    <>
      <div className='dashboard'>

        <div className='manage-rule-wrapper'>
          <div className='inner-wrapper recent-request-section rule-list-wrapper'>
            <div className='table-head'>
              <h5 className='manage-rule-head'>Upsell</h5>
              <div>
                <span className='add-rule-btn' onClick={() => { navigate("/rule-list") }}>Rule List</span>
              </div>
            </div>
            <div>
              <div className='cart-id-section'>
                <div className='cart-id-inner-wrapper'>
                  <input type='text' name='cartId' placeholder='Enter Cart Id' value={cartId} onChange={((e) => {
                    setUpsellList([])
                    setFetchUpsell(false)
                    setCartId(e.target.value)
                  })} />
                </div>
                <div className='cart-submit'>
                  <button onClick={() => {
                    setFetchUpsell(true)
                  }}>Fetch upsell</button>
                </div>
              </div>
              {upsellList.length > 0 ?
                <div className="gridContainer">
                  {upsellList}
                </div>
                : loading === true ? (
                  <>
                    <span className='fetching-data'>Fetching data...</span>
                    <BarLoader color={"#123abc"} width={'100%'} loading={loading} />
                  </>
                ) :
                  <div className='no-rules-found-msg'></div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpsellList;
