import React from 'react';
import LayoutsRoutes from "./Routes/LayoutsRoutes";
import { ToastContainer, toast } from 'react-toastify';
import "@aws-amplify/ui-react/styles.css";
import { withAuthenticator } from "@aws-amplify/ui-react";
import 'react-toastify/dist/ReactToastify.css';
import './custom.scss';

const App = ({ signOut }) => {

  return (
    <>
      <button className='logout-btn' onClick={signOut}>Logout</button>
      <div className="App">
        <LayoutsRoutes />
        <ToastContainer
          toastClassName="custom-toast"
          position="bottom-right"
          autoClose={2000} />
      </div>
    </>
  );
}

export default withAuthenticator(App);