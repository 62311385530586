import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import { service } from './service';

const Rule = () => {

  //#region variables
  const location = useLocation();
  let originalDate = new Date();
  // #region convertUtcToDiffTimezone
  const convertUtcToDiffTimezone = (inputDateTime, selectedTimezone) => {
    const utcDateTime = new Date(inputDateTime);
    const pstDateTime = new Date(utcDateTime.toLocaleString('en-US', { timeZone: selectedTimezone }));
    const year = pstDateTime.getFullYear();
    const month = String(pstDateTime.getMonth() + 1).padStart(2, '0');
    const day = String(pstDateTime.getDate()).padStart(2, '0');
    const hours = String(pstDateTime.getHours()).padStart(2, '0');
    const minutes = String(pstDateTime.getMinutes()).padStart(2, '0');
    const seconds = String(pstDateTime.getSeconds()).padStart(2, '0');
    const meridiem = (pstDateTime.getHours() < 12) ? 'AM' : 'PM';
    const outputDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    return outputDateTime
  }
  //#endregion
  const ruleId = location.state?.ruleId;
  const navigate = useNavigate();
  const [ruleDetails, setRuleDetails] = useState({});
  const [ruleConditionals, setRuleConditionals] = useState([]);
  const [eventList, setEventList] = useState(<></>);
  const [upsell, setUpSell] = useState(<></>);
  const [tickets, setTickets] = useState(<></>);
  const [timezoneList, setTimezoneList] = useState(<></>);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedTimezone, setSelectedTimezone] = useState('America/Los_Angeles');
  //#region convertUtcToPST
  const covertUtcToPST = (inputDateTime, selectedTimezone) => {
    const utcDateTime = new Date(inputDateTime);
    const pstDateTime = new Date(utcDateTime.toLocaleString('en-US', { timeZone: selectedTimezone }));
    const year = pstDateTime.getFullYear();
    const month = String(pstDateTime.getMonth() + 1).padStart(2, '0');
    const day = String(pstDateTime.getDate()).padStart(2, '0');
    const hours = String(pstDateTime.getHours()).padStart(2, '0');
    const minutes = String(pstDateTime.getMinutes()).padStart(2, '0');
    const seconds = String(pstDateTime.getSeconds()).padStart(2, '0');
    const meridiem = (pstDateTime.getHours() < 12) ? 'AM' : 'PM';
    const outputDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return outputDateTime
  }
  //#endregion
  let converted = covertUtcToPST(originalDate, 'America/Los_Angeles')
  const [defaultTime, setDefaultTime] = useState(converted);
  //#endregion

  useEffect(() => {
    ruleId ? fetchRuleDetails(ruleId) : addNewRuleSetup();
    fetchTimezoneList();
  }, []);

  // #region updateOrCreateRule
  useEffect(() => {
    async function updateOrCreate() {
      if (isProcessing) {
        if (ruleId) {
          // update
          const ruleUpdateStatus = await service.updateRule(ruleDetails, ruleId);
          if (ruleUpdateStatus.status == 200) {
            toast.success('Rule saved successfully!');
            setTimeout(() => {
              setIsProcessing(false);
              navigate("/rule-list");
            }, 2000);
          };
        } else {
          // create
          const ruleCreateStatus = await service.createRule(ruleDetails, ruleId);
          if (ruleCreateStatus.status == 200) {
            toast.success('Rule saved successfully!');
            setTimeout(() => {
              setIsProcessing(false);
              navigate("/rule-list");
            }, 2000);
          };
        }
      }
    }
    updateOrCreate();
  }, [ruleDetails]);
  //#endregion

  //#region addNewRuleSetup
  const addNewRuleSetup = () => {
    fetchEventList();
    setRuleDetails({
      always_active: false,
      show_web: false,
      show_kiosk: false,
      description: null,
      end_time: convertUtcToDiffTimezone(originalDate, selectedTimezone),
      start_time: convertUtcToDiffTimezone(originalDate, selectedTimezone),
      order: 0,
      promo_code: "",
      promo_code_description: "",
      promo_code_notes: "",
      rule_condition: [],
      rule_id: null,
      rule_name: "",
      rule_upsell: [{
        event_id: "",
        type: "ticketure",
        title: "",
        sub_title: "",
        image_url: "",
        link: "",
        ticket_type_id: "",
      }],
      timezone: selectedTimezone
    })
    setRuleConditionals([{
        condition_id: "1",
        date_from: null,
        date_to: null,
        event_id: null,
        not_operator: false,
        operation: "event",
        priority: 1,
        type: null
    }]);
  }
  //#endregion

  //#region fetchRuleDetails
  const fetchRuleDetails = async () => {
    await fetchEventList()
    const ruleDetails = await service.fetchRuleDetails(ruleId)
    if (ruleDetails.status == 200) {
        setRuleDetails(ruleDetails.data[0]);
        setSelectedTimezone(ruleDetails.data[0].timezone);
        if(ruleDetails.data[0].rule_condition === null || ruleDetails.data[0].rule_condition.length < 1 ){
            setRuleConditionals([{
                condition_id: "1",
                date_from: null,
                date_to: null,
                event_id: null,
                not_operator: false,
                operation: "event",
                priority: 0,
                type: null
            }]);
        } else {
            setRuleConditionals(ruleDetails.data[0].rule_condition);
        }
        if (ruleDetails.data[0].rule_upsell === null || ruleDetails.data[0].rule_upsell.length < 1) {
          setRuleDetails(prevRuleDetails => ({
            ...prevRuleDetails,
            rule_upsell: [
              {
                event_id: "",
                type: "ticketure",
                title: "",
                sub_title: "",
                image_url: "",
                link: ""
              }
            ]
          }));
        }
    } else {
        showExceptionMessage()
    }
  };
  //#endregion

  //#region BuildAndSetTickets
  const BuildAndSetTickets = (data) => {

    const events = data.event_template?._data || [];
    const groups = data.ticket_group?._data || [];
    const types = data.ticket_type?._data || [];
  
    const result = {};
  
    // Step 1: Initialize the result object with events
    events.forEach((e) => {
      result[e.id] = {
        ...e,
        groups: {}
      };
    });
  
    // Step 2: Add groups to the corresponding event
    groups.forEach((group) => {
      const eventId = group.event_template_id;
      if (result[eventId]) {
        result[eventId].groups[group.id] = {
          ...group,
          types: []
        };
      }
    });

    // Step 3: Add ticket types to the corresponding group
    types.forEach((type) => {
      const groupId = type.ticket_group_id;
      for (const eventId in result) {
        if (result[eventId].groups[groupId]) {
          result[eventId].groups[groupId].types.push(type);
          break;
        }
      }
    });

    setTickets(result);
  }
  //#endregion

  //#region fetchEventList
  const fetchEventList = async () => {
    let res = await service.fetchRuleEvents();
    if (res.status == 200) {
      const events = res.data?.event_template?._data ? res.data?.event_template?._data : [];

      setEventList(events.sort((a, b) => a.name.localeCompare(b.name)).map(({ id, name }) => {
        return <option value={id} key={id}>{name}</option>
      }));
      setUpSell(events.sort((a, b) => a.name.localeCompare(b.name)).map(({ id, name }) => {
        return <option value={id} key={id}>{name}</option>
      }));
      
      BuildAndSetTickets(res.data);

    } else {
      showExceptionMessage()
    };
  };
  //#endregion

  //#region fetchTimezoneList
  const fetchTimezoneList = async () => {
    const timezoneLists = await service.fetchTimeZones();
    if (timezoneLists.status == 200) {
      setTimezoneList(timezoneLists.data.data.map(({ name, abbrev }, index) => {
        return <option value={name} key={index}>{name} - {abbrev}</option>
      }))
    } else {
      showExceptionMessage()
    }
  }
  //#endregion

  //#region showExceptionMessage
  const showExceptionMessage = () => {
    toast.error('Sorry, Something went wrong!')
  }
  //#endregion

  //#region handleRuleChange
  const handleRuleChange = async (e) => {
    e.preventDefault();
    const { value, name } = e?.target;
    setErrors({
      ...errors,
      [name]: false
    })
    setRuleDetails(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  //#endregion

  //#region handleRuleUpsellChange
  const handleRuleUpsellChange = async (e) => {
    e.preventDefault();
    const { value, name } = e?.target;
    setErrors({
      ...errors,
      [name]: false
    });
  
    setRuleDetails(prevState => {
      let updatedRuleUpsell;
  
      if(Array.isArray(prevState.rule_upsell) &&  prevState.rule_upsell.length > 0){
        updatedRuleUpsell = {
          ...prevState.rule_upsell[0],
          [name]: value
        };
      } else {
        updatedRuleUpsell = {
          [name]: value
        };
      }
      
      const updatedRuleUpsellArray = Array.isArray(prevState.rule_upsell) && prevState.rule_upsell.length > 0
      ? [updatedRuleUpsell, ...prevState.rule_upsell.slice(1)]
      : [updatedRuleUpsell];
  
      return {
        ...prevState,
        rule_upsell: updatedRuleUpsellArray
      };
    });
  };
  //#endregion

  //#region handleOperationChange
  const handleOperationChange = (operator, index) => {
    setRuleConditionals(prevState => {
      const updatedConditions = [...prevState];
      const conditionToUpdate = updatedConditions[index];
  
      if (!conditionToUpdate) {
        return prevState;
      }
  
      conditionToUpdate.not_operator = !operator;

      updatedConditions[index] = conditionToUpdate;
      return updatedConditions;
    });
  };
  //#endregion

  //#region removeCondition
  const removeCondition = async (index) => {
    const id = ruleConditionals[index].condition_id;
    let removeItem = false;
    if(typeof(id) === "number"){
      const deleteStatus = await service.deleteRuleCondition(id);
      if(deleteStatus.status == 200){
        removeItem = true;
      } else {
        showExceptionMessage()
      }
    } else {
      removeItem = true;
    }

    if(removeItem){
      toast.success('Condition removed successfully!');
      setRuleConditionals(prevState => {
        const updatedConditions = prevState.filter((condition, i) => i !== index);
        return updatedConditions;
      });
    }

  };
  //#endregion

  //#region operationChange
  const operationChange = (operationName, i) => {
    setRuleConditionals(prevState => {
      const updatedConditions = [...prevState];
      const conditionToUpdate = updatedConditions[i];
  
      if (!conditionToUpdate) {
        return prevState;
      }
  
      if (operationName === 'date-time') {
        conditionToUpdate.operation = operationName;
        conditionToUpdate.date_to = convertUtcToDiffTimezone(originalDate, ruleDetails?.timezone);
        conditionToUpdate.date_from = convertUtcToDiffTimezone(originalDate, ruleDetails?.timezone);
      } else {
        conditionToUpdate.operation = operationName;
      }
  
      updatedConditions[i] = conditionToUpdate;
      return updatedConditions;
    });
  };
  //#endregion

  //#region handleConditionalChange
  const handleConditionalChange = async (e) => {
    e.preventDefault();
    const { value, name } = e?.target;
    const conditionId = e?.target.getAttribute('data-id');
    let isLastCondition = false;

    setRuleConditionals(prevState => {
      let updatedConditions = prevState.map((condition, i) => {
        if (parseInt(condition.condition_id) === parseInt(conditionId)) {
          isLastCondition = i === prevState.length - 1;
          return {
            ...condition,
            [name]: value
          };
        }
        return condition;
      });
  
      // Check if the new object needs to be added
      if (name === "type" && value !== "0" && isLastCondition) {
        updatedConditions = [
          ...updatedConditions,
          {
            condition_id: (updatedConditions.length + 1).toString(),
            date_from: null,
            date_to: null,
            event_id: null,
            not_operator: false,
            operation: "event",
            priority: updatedConditions.length + 1,
            type: null
          }
        ];
      }
  
      return updatedConditions;
    });
  };
  //#endregion

  //#region handleTimezoneChange
  const handleTimezoneChange = (e) => {
    e.preventDefault();
    const { value, name, id } = e?.target;
    let fromDate;
    let toDate;
    if (name == 'timezone') {
      setSelectedTimezone(value);
      fromDate = convertUtcToDiffTimezone(originalDate, value);
      toDate = convertUtcToDiffTimezone(originalDate, value);
      setRuleDetails(prevState => ({
        ...prevState,
        [name]: value,
        start_time: fromDate,
        end_time: toDate
      }));
    }
  }
  //#endregion

  //#region validationsCheck
  const validationsCheck = () => {
    if(ruleDetails.rule_upsell.length > 0 && ruleConditionals.length > 0){
      if(ruleDetails.rule_upsell[0].type === "ticketure" && ruleDetails.rule_upsell[0].event_id != "" || ruleDetails.rule_upsell[0].type === "non-ticketure"){
        if(ruleDetails.rule_name !== "" && ruleDetails.start_time !== "" && ruleDetails.end_time !== ""){
          return true;
        };
      };
    };

    setErrors({
      ...errors,
      submit: true
    });

    return false;
  }
  //#endregion

  //#region processConditionals
  const processRuleConditions = () => {
    let conditions = ruleConditionals;
    conditions.map((item, i) => {
      if(typeof(item.condition_id) === "string"){
        // remove temp id string for new conditionals
        item.condition_id = null;
      };
    })
    return conditions;
  }
  //#endregion

  //#region saveRule
  const saveRule = async () => {
    const validationCheck = validationsCheck();
    if(validationCheck){
      setIsProcessing(true);
      const conditionals = await processRuleConditions();
      setRuleDetails(prevState => ({
        ...prevState,
        rule_condition: conditionals
      }));
    };
  }
  //#endregion

  return(
    <>
        <div className='wrapper'>
            <div className='header-section'>
                <div className='header-inner-wrapper top-head'>
                    <h4>{ruleId ? 'Update Rule' : 'Create Rule'}</h4>
                    <span className='view-list-btn' onClick={() => { navigate("/rule-list") }}>View list</span>
                </div>
            </div>
        </div>

        <div className='wrapper body-wrapper'>
            <div className='body-section'>

              { isProcessing ? 
                  <div className='rule-submit mb-4'>
                    <div className='submit-wrapper'>
                      <button className='rule-submit-btn'>PROCESSING...</button>
                    </div>
                  </div>
                  :
                  <div className='rule-submit mb-4'>
                    <div className='submit-wrapper'>
                      { errors.submit && 
                        <p className='submit-error'>Please complete the form</p>
                      }
                      <button
                        className='rule-submit-btn' 
                        onClick={() => {saveRule()}}>
                        SAVE
                      </button>
                    </div>
                  </div>
                }

                <div className='item'>
                    <div className='inner-item'>
                        <input 
                            type="text" 
                            name="rule_name" 
                            className={errors.ruleName ? 'error' : ''}
                            placeholder='Rule Name' 
                            value={ruleDetails.rule_name ? ruleDetails.rule_name : ""}
                            onChange={handleRuleChange} 
                        />
                    </div>
                    <div className='inner-item active' style={{width: '300px'}}>
                        <label>Active</label>
                        <input 
                            type="checkbox" 
                            name='always_active' 
                            className='checkbox' 
                            checked={ruleDetails.always_active} 
                            onChange={() => {
                                setRuleDetails(prevState => ({
                                    ...prevState,
                                    ['always_active']: !ruleDetails.always_active
                                }));
                            }}
                        />
                    </div>
                    
                    <div className='inner-item'>
                        <input type="text" name="promo_code" placeholder='Promo Code' value={ruleDetails.promo_code ? ruleDetails.promo_code : ""} onChange={handleRuleChange} />
                    </div>
                </div>

                <div className='item'>
                  <div className='inner-item' style={{position: 'relative', width: '100px'}}>
                    <label>Show on:</label>
                    <div>
                      <label>Web</label>
                      <input 
                          style={{right: '0'}}
                          type="checkbox" 
                          name='show_web' 
                          className='checkbox' 
                          checked={ruleDetails.show_web} 
                          onChange={() => {
                              setRuleDetails(prevState => ({
                                  ...prevState,
                                  ['show_web']: !ruleDetails.show_web
                              }));
                          }}
                      />
                    </div>

                    <div>
                      <label>Kiosk</label>
                      <input 
                          style={{right: '0'}}
                          type="checkbox" 
                          name='show_kiosk' 
                          className='checkbox' 
                          checked={ruleDetails.show_kiosk} 
                          onChange={() => {
                              setRuleDetails(prevState => ({
                                  ...prevState,
                                  ['show_kiosk']: !ruleDetails.show_kiosk
                              }));
                          }}
                      />
                    </div>
                  </div>
                </div>

                <div className='item'>
                    <div className='inner-item'>
                        <textarea 
                            type="text" 
                            name="promo_code_description" 
                            placeholder='Promo Code Description' 
                            value={ruleDetails.promo_code_description ? ruleDetails.promo_code_description : ""}
                            onChange={handleRuleChange} >
                        </textarea>
                    </div>
                    <div className='inner-item'>
                        <textarea 
                            type="text" 
                            name="promo_code_notes" 
                            placeholder='Promo Code Notes' 
                            value={ruleDetails.promo_code_notes ? ruleDetails.promo_code_notes : ""}
                            onChange={handleRuleChange} >
                        </textarea>
                    </div>
                </div>

                <div className='item '>
                    <div className='inner-item'>
                        <label>Start</label>
                        <input 
                            type="datetime-local" 
                            name='start_time' 
                            className={errors.start_time ? 'error' : ''} 
                            value={ruleDetails.start_time ? convertUtcToDiffTimezone(ruleDetails.start_time, ruleDetails.timeZone) : defaultTime}
                            onChange={handleRuleChange} />
                    </div>
                    <div className='inner-item'>
                        <label>End</label>
                        <input 
                            type="datetime-local" 
                            name='end_time' 
                            className={errors.end_time ? 'error' : ''} 
                            value={ruleDetails.end_time ? convertUtcToDiffTimezone(ruleDetails.end_time, ruleDetails.timeZone) : defaultTime}
                            onChange={handleRuleChange} />
                    </div>
                    <div className='inner-item'>
                        <label>Timezone</label>
                        <select 
                            name='timezone' 
                            id='timezone-0' 
                            value={ruleDetails.timezone ? ruleDetails.timezone : ""} 
                            onChange={handleTimezoneChange} >
                                <option value='America/Los_Angeles'>America/Los_Angeles - PST</option>
                                {timezoneList}
                        </select>
                    </div>
                </div>

                <div className='upsell-block'>
                    <div className='upsell-item'>
                        <label>Upsell Type</label>
                        <select
                            name='type'
                            id='event-0' 
                            value={ruleDetails.rule_upsell ? ruleDetails.rule_upsell[0].type : "0"} 
                            className={errors.upsell_type ? 'error' : ''} 
                            onChange={handleRuleUpsellChange} >
                                <option value='0'>Select Type</option>
                                <option value='ticketure'>Ticketure</option>
                                <option value='non-ticketure'>Non Ticketure</option>
                        </select>
                    </div>

                    {ruleDetails.rule_upsell && ruleDetails.rule_upsell[0].type == 'ticketure' && 
                    <>
                      <div className='upsell-item'>
                        <label>Upsell Item</label>
                        <select 
                          name='event_id' 
                          value={ruleDetails.rule_upsell ? ruleDetails.rule_upsell[0].event_id : "0" } 
                          className={errors.upsell_ticketure ? 'error' : ''} 
                          onChange={handleRuleUpsellChange} >
                              <option value='0'>Select Upsell</option>
                              {upsell}
                        </select>
                      </div>
                      <div className='upsell-item'>
                        <label>Upsell Ticket (optional)</label>
                        <select
                          disabled={!ruleDetails.rule_upsell || (ruleDetails.rule_upsell[0]?.event_id === "0" || ruleDetails.rule_upsell[0]?.event_id === "")}
                          name='ticket_type_id'
                          value={ruleDetails.rule_upsell ? ruleDetails.rule_upsell[0].ticket_type_id : ""}
                          className={errors.ticket_type_id ? 'error' : ''}
                          onChange={handleRuleUpsellChange} >
                            <option value="">Ticket Type</option>
                            {ruleDetails.rule_upsell && ruleDetails.rule_upsell[0]?.event_id !== "0" && ruleDetails.rule_upsell[0]?.event_id !== "" && tickets[ruleDetails.rule_upsell[0]?.event_id]?.groups &&
                              Object.values(tickets[ruleDetails.rule_upsell[0]?.event_id]?.groups).map(group => (
                                <optgroup key={group.id} label={group.name}>
                                  {group.types.map(type => (
                                    <option key={type.id} value={type.id}>
                                      {type.name}
                                    </option>
                                  ))}
                                </optgroup>
                              ))
                            }
                        </select>
                      </div>
                    </>
                        
                    }

                    { ruleDetails.rule_upsell && ruleDetails.rule_upsell[0].type == 'non-ticketure' &&
                        <div className='upsell-item non-tix'>
                            <label>Upsell</label>

                            <input type="text" className={errors.title ? 'error' : ''} name="title" placeholder='Upsell Title' value={ruleDetails.rule_upsell ? ruleDetails.rule_upsell[0].title : ""}
                                    onChange={handleRuleUpsellChange} />

                            <input type="text" className={errors.sub_title ? 'error' : ''} name="sub_title" placeholder='Upsell Sub Title' value={ruleDetails.rule_upsell ? ruleDetails.rule_upsell[0].sub_title : ""}
                                onChange={handleRuleUpsellChange} />

                            <input type="text" className={errors.image_url ? 'error' : ''} name="image_url" placeholder='Upsell Image URL' value={ruleDetails.rule_upsell ? ruleDetails.rule_upsell[0].image_url : ""}
                                onChange={handleRuleUpsellChange} />

                            <input type="text" className={errors.link ? 'error' : ''} name="link" placeholder='Upsell Link' value={ruleDetails.rule_upsell ? ruleDetails.rule_upsell[0].link : ""}
                                onChange={handleRuleUpsellChange} />
                        </div>
                    }
                </div>

                <div className='body-section mb-5'>
                    <div className='item '>
                        <div className='inner-item'>
                            <label>Conditionals</label>
                        </div>
                    </div>
                    {/* map saved rule conditionals start */}
                    { ruleConditionals && ruleConditionals.length > 0 &&
                        ruleConditionals.map((item, i) => {
                            return(
                                <div className='condition-block' key={i}>
                                    <div className='condition-wrapper'>
                                        <div className='operation-section'>
                                            <>
                                                <label className='operation-label'> Inverse Rule </label>
                                                <input 
                                                    type="checkbox" 
                                                    name='not_operator' 
                                                    checked={item.not_operator} 
                                                    className='not-operator'
                                                    onChange={() => {handleOperationChange(item.not_operator, i)}}
                                                />
                                            </>
                                            <button 
                                                className={item.operation == 'date-time' ? 'operation-item active' : 'operation-item'} 
                                                onClick={() => {operationChange('date-time', i)}}>
                                                Date/Time
                                            </button>
                                            <button
                                                className={item.operation == 'event' ? 'operation-item active' : 'operation-item'}
                                                onClick={() => {operationChange('event', i)}}>
                                                Event
                                            </button>
                                            { ruleConditionals.length > 1 &&
                                              <button
                                                className='operation-item remove' 
                                                onClick={() => {removeCondition(i)}}>
                                                  Remove
                                              </button>
                                            }
                                        </div>

                                        <div className='condition-section'>
                                            { item.operation == 'date-time' ? 
                                                <>
                                                    <div className='inner-item'>
                                                        <label>From</label>
                                                        <input 
                                                          name='date_from' 
                                                          id='date_from'
                                                          data-id={item.condition_id}
                                                          className={errors.fromDate ? 'error ' : ''} 
                                                          value={item.date_from ? item.date_from : defaultTime}  
                                                          type="datetime-local" 
                                                          onChange={(e) => handleConditionalChange(e)} />
                                                    </div>
                                                    <div className='inner-item'>
                                                        <label>To</label>
                                                        <input 
                                                          name='date_to' 
                                                          id='date_to'
                                                          data-id={item.condition_id} 
                                                          className={errors.date_to ? 'error' : ''} 
                                                          value={item.date_to ? item.date_to : defaultTime}  
                                                          type="datetime-local"
                                                          onChange={(e) => handleConditionalChange(e)} />
                                                    </div>
                                                </> 
                                                :
                                                <div className='inner-item event-option'>
                                                    <label>Event</label>
                                                    <select 
                                                      name='event_id' 
                                                      id='event_id'
                                                      data-id={item.condition_id}
                                                      value={item.event_id ? item.event_id : ""}
                                                      className={errors.event_id ? 'error' : ''} 
                                                      onChange={(e) => handleConditionalChange(e)} >
                                                        <option value='0'>Select Event</option>
                                                        {eventList}
                                                    </select>
                                                </div>
                                            }
                                            <div className='inner-item'>
                                                <label>Type</label>
                                                <select
                                                  name='type' 
                                                  id='type'
                                                  data-id={item.condition_id}
                                                  value={item.type ? item.type : ""} 
                                                  onChange={(e) => handleConditionalChange(e)} >
                                                    <option value='0'>ADD NEW RULE (select type)</option>
                                                    <option value='and'>AND</option>
                                                    <option value='or'>OR</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              // map saved rule conditionals end
                            )
                        })
                    }
                </div>

                { isProcessing ? 
                  <div className='rule-submit'>
                    <div className='submit-wrapper'>
                      <button className='rule-submit-btn'>PROCESSING...</button>
                    </div>
                  </div>
                  :
                  <div className='rule-submit'>
                    <div className='submit-wrapper'>
                      { errors.submit &&
                        <p className='submit-error'>Please complete the form</p>
                      }
                      <button
                        className='rule-submit-btn' 
                        onClick={() => {saveRule()}}>
                        SAVE
                      </button>
                    </div>
                  </div>
                }

            </div>
        </div>
    </>
  )
}

export default Rule;