import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import NotFound from '../Components/Common/NotFound'
import Rule from '../Components/Rules/Rule2';
import RuleList from '../Components/Rules/RuleList';
import UpsellList from '../Components/Rules/UpsellList';

const LayoutsRoutes = () => {

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/rule-list" />} />
        <Route path="/upsell-list" element={<UpsellList />} />
        <Route path="/create-rule" element={<Rule />} />
        <Route path="/edit-rule" element={<Rule />} />
        <Route path="/rule-list" element={<RuleList />} />
        <Route path="*" element={<Navigate to="/404" />}></Route>
        <Route path="/404" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default LayoutsRoutes;
