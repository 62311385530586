import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './custom.scss';
import { useNavigate } from 'react-router-dom';
import { service } from './service';
import ConfirmationModal from '../Common/ConfirmModal/ConfirmationModal'
import { BarLoader } from "react-spinners";

const RuleList = () => {

  const navigate = useNavigate();
  const [rules, setRules] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchRules()
  }, [])

  const fetchRules = async () => {
    const ruleList = await service.fetchRules()
    if (ruleList.status == 200) {
      setLoading(false)
      setRules(ruleList?.data.map((item) => {
        return { ...item, status: item.always_active === true ? 'Active' : 'Inactive' }
      }))
    } else {
      showExceptionMessage()
    }
  }

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = [...rules];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setRules(newItems);

    const data = {
      mapping: newItems.map((item, index) => {
        return {
          rule_id: item.rule_id,
          order: index + 1
        }
      })
    }
    const ruleOrderstatus = await service.updateRuleOrder(data)
    if (ruleOrderstatus.status == 200) {
      toast.success(ruleOrderstatus.data.message)
    } else {
      showExceptionMessage()
    }
  };

  const updateRuleStatus = async (status, ruleId) => {
    const data = { always_active: status }
    const ruleStatus = await service.updateRuleStatus(data, ruleId)
    if (ruleStatus.status == 200) {
      setRules(rules.map((item) => {
        return item.rule_id == ruleId ? { ...item, always_active: status, status: status ? 'Active' : 'Inactive' } : item
      }))
      toast.success(ruleStatus.data.message)
    } else {
      showExceptionMessage()
    }
  }

  // const handleConfirm = (rule_id) => {
  //   console.log('Action confirmed!' + rule_id);
  // };

  const deleteRule = async (ruleId) => {
    const ruleDeleteStatus = await service.deleteRule(ruleId)
    if (ruleDeleteStatus.status == 200) {

      const data = {
        mapping: rules.filter((item) => item.rule_id != ruleId).map((item, index) => {
          return {
            rule_id: item.rule_id,
            order: index + 1
          }
        })
      }

      const ruleOrderstatus = await service.updateRuleOrder(data)
      if (ruleOrderstatus.status == 200) {
        setRules(rules.filter((item) => item.rule_id != ruleId))
        toast.success(ruleDeleteStatus.data.message)
      }
    } else {
      showExceptionMessage()
    }
  }
  const showExceptionMessage = () => {
    toast.error('Sorry, Something went wrong!')
  }

  return (
    <>
      <div className='dashboard'>

        <div className='manage-rule-wrapper'>
          <div className='inner-wrapper recent-request-section rule-list-wrapper'>
            <div className='table-head'>
              <h5 className='manage-rule-head'>Manage Rules</h5>
              <div>
                <span className='add-rule-btn' onClick={() => { navigate("/upsell-list") }}>Test Upsell Rule</span>
                <span className='add-rule-btn' onClick={() => { navigate("/create-rule") }}>Add New Upsell Rule</span>
              </div>
            </div>
            <div>
              {rules.length > 0 ? <div className="table-responsive">
                <table>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <thead>
                      <th>Order</th>
                      <th>Name</th>
                      <th>Status</th>
                      <th>Action</th>
                    </thead>
                    <Droppable droppableId="items">
                      {(provided) => (

                        <tbody {...provided.droppableProps} ref={provided.innerRef}>

                          {rules.map(({ rule_id, rule_name, status, always_active }, index) => (
                            <Draggable key={String(rule_id)} draggableId={String(rule_id)} index={index}>
                              {(provided) => (
                                <tr
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <td>{index + 1}</td>
                                  <td>{rule_name}</td>
                                  <td>{status}</td>
                                  <td className='edit-column-wrapper'>
                                    <div className='edit-section'>
                                      <span className='cancel-btn' onClick={() => {
                                        updateRuleStatus(!always_active, rule_id)
                                      }}>{always_active ? 'Disable' : 'Enable'}</span>
                                      <span className='cancel-btn' onClick={() => { navigate('/edit-rule', { state: { ruleId: rule_id } }) }}>Edit</span>
                                      {/* <span className='delete-btn' onClick={() => {
                                        deleteRule(rule_id)
                                      }}> */}
                                      <ConfirmationModal onConfirm={() => {
                                        deleteRule(rule_id)
                                      }} />
                                      {/* </span> */}
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </DragDropContext>
                </table>

              </div> : loading ? (
                <>
                  <span className='fetching-data'>Fetching data...</span>
                  <BarLoader color={"#123abc"} width={'100%'} loading={loading} />
                </>
              ) :
                <div className='no-rules-found-msg'>No records found!</div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RuleList;
